//import {ChartBarIcon} from '@heroicons/react/24/outline'
import {ChartBarIcon,ComputerDesktopIcon,PresentationChartLineIcon} from '@heroicons/react/24/solid'
import React,{useState, useEffect} from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import { useQuery } from "react-query";
import bghomebannerimg from '../images/slider.webp';
import bghomebannetwoimg from '../images/bg-back1.webp';
import bghomebannethreeimg from '../images/bg-back2.webp';
import bghomebannefourimg from '../images/bg-back3.webp';
import {Carousel,Rating,Card,Accordion} from 'flowbite-react';
import { NavLink } from "react-router-dom";
function Home() {
    const user_token = localStorage.getItem("coconuttoken");
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_token}`
    };
    const BASE_URL = "https://application.coconuttickets.com";
    const [isMobile, setIsMobile] = useState(false);
    const [faqs, setFaq] = useState([]);
    const [posts, setPosts] = useState([]);
    const [carousels, setCarousel] = useState([]);
    const fetchContents = async () => {
        const response = await axios.get( `${BASE_URL}/api/public_contents?page=home`, { headers });
        return response.data;
    };
    const fetchfaq= async () => {
        const response = await axios.get( `${BASE_URL}/api/public_faqs?page=home`, { headers });
        return response.data;
    };
    const ShowhomeContents = () => {
        const { isLoading, isSuccess, data, error }= useQuery('fetchContents',fetchContents)
        useEffect(() => {
            if(isLoading === false && data){
                setPosts(data);
            }
        }, [isLoading, data])
        if (isLoading) return <div className="blur-2xlv h-screen w-screen">
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="container mx-auto mt-80">
                                            <div className="grid">
                                                <div className="space-y-3">
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             </div>
        if (isSuccess) return  <Homefullpage />
    }
    const ShowhomeCarousel = () => {
        useEffect( () => { 
            async function fetchData() {
                axios.get(`${BASE_URL}/api/public_carousels?page=home`, { headers })
                .then(response => {
                    var rows = response.data;
                    setCarousel(rows);
                })
                .catch(error => {
                    console.error(error);
                })
    
            }
            fetchData();
        }, []);
    }
    const ShowhomeFaq = () => {
        const { isLoading, isError, data, error }= useQuery('fetchfaq',fetchfaq,{
            onSuccess: (data) =>  {
            // Update local state with the data from the query
            setFaq(data);
        }})
    
    }
    const MobileDeviceDetector = () => {
      //  const [isMobile, setIsMobile] = useState(false);
      
        useEffect(() => {
          const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed
       
          setIsMobile(mobileMediaQuery.matches);
      
        }, []);
      
        
      }
    const reviews  =  carousels.reduce((resultArray, item, index) => { 
        var chunkIndex = Math.floor(index/3) 
        if(isMobile){
            chunkIndex = Math.floor(index) 
        }  
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)
      
        return resultArray
    }, [])
    const Bannerhome = () =>{
       return (posts.length != 0 ) ? (<section className="relative table w-full md:py-36 lg:py-44 studio-wrapper overflow-hidden">
                <div className="absolute inset-0 home-banner-bg" id="overlay" style={{backgroundImage: `url(${bghomebannerimg})`}}></div>
                    {posts?.map(fetchContents => {
                        if(fetchContents.section === 'banner'){
                            return  <div className="md:container md:mx-auto">
                                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                                            <div className="md:col-span-6 z-0 max-sm:px-6">
                                                <div className="ltr:md:mr-6 rtl:md:ml-6">
                                                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl  z-50 text-gray-100">{fetchContents.title}
                                                </h1>
                                                <h2 className="text-brand-600 text-3xl block font-bold mb-5">{fetchContents.sub_title} </h2>
                                                <p className="text-slate-400 text-xl z-50 text-white">{fetchContents.content}  </p>
                                                <div className="mt-6"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">{fetchContents.button_text} </a> </div>
                                                <br/>
                                                </div>
                                            </div>
                                            <div className="md:col-span-6 max-sm:px-6 max-sm:hidden">
                                                <div className="relative"> <img className="float-right" src="/images/marketing.webp" alt="banner1" /> </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                        }
                    })}   
            </section>)  : (<section className="relative table w-full md:py-36 lg:py-44 studio-wrapper overflow-hidden">
                <div className="absolute inset-0 home-banner-bg" id="overlay" style={{backgroundImage: `url(${bghomebannerimg})`}}></div>
                    {posts?.map(fetchContents => {
                        if(fetchContents.section === 'banner'){
                            return  <div className="md:container md:mx-auto">
                                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                                            <div className="md:col-span-6 z-0 max-sm:px-6">
                                                <div className="ltr:md:mr-6 rtl:md:ml-6">
                                                <h2 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50 text-gray-100">{fetchContents.title} <br/>
                                                    <span className="text-brand-600 text-3xl">{fetchContents.sub_title} </span></h2>
                                                <p className="text-slate-400 text-xl z-50">{fetchContents.content}  </p>
                                                <div className="mt-6"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">{fetchContents.button_text} </a> </div>
                                                <br/>
                                                </div>
                                            </div>
                                            <div className="md:col-span-6 max-sm:px-6 max-sm:hidden">
                                                <div className="relative"> <img className="float-right" src="/images/marketing.webp" alt="banner1" /> </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                        }
                    })}   
            </section>)
    }
    const Homefullpage = () =>{
        return(
            <>
             {posts?.map(fetchContents => {
                if(fetchContents.section === 'meta_data'){
                    return <Helmet><title>{fetchContents.title}</title><meta name="description" content={fetchContents.content} /> </Helmet>
                }
            })}
            <Bannerhome />
            <section className="relative bg-gray-50 dark:bg-slate-800 py-16 md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 md:col-span-6 home-banner-bg" style={{backgroundImage: `url(${bghomebannetwoimg})` }}>
                        <div className="grid grid-cols-1 gap-6"> <img src="/images/bg1.webp" className="" alt="banner image" /> </div>
                    </div>
                    <div className="lg:col-span-7 md:col-span-6">
                    {posts?.map(fetchContents => {
                        if(fetchContents.section === 'Experience'){
                            return <div className="ltr:lg:ml-5 rtl:lg:mr-5 max-sm:px-4">
                                        <div className="flex items-center mb-4"> <span className="text-brand-600 text-2xl font-bold mb-0"><span className="counter-value text-6xl font-bold" data-target="7">7</span>+</span> <span className="self-center ml-4 ltr:ml-2 rtl:mr-2 font-semibold text-xl">Years Experience</span> </div>
                                        <h2 class="font-bold lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5">{fetchContents.title}<br/>
                                        {fetchContents.sub_title} </h2>
                                        <p className="text-slate-600 max-w-xl text-lg">{fetchContents.content}</p>
                                        <div className="mt-6"> <a href="http://app.coconuttickets.com/sign-up" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md mt-2"><i className="uil uil-airplay"></i> {fetchContents.button_text}</a> </div>
                                    </div>
                        }
                    })}    
                    </div>
                    </div>
                </div>
                <div className="md:container md:mx-auto mt-16">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-brand-600 bg-white rounded-xl border-style text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-codesandbox h-10 w-10 stroke-1 text-brand-600 mx-auto">
                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
                        <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
                        <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <div className="content mt-6"> <div className="title h5 text-xl font-semibold hover:text-brand-600">Sell tickets to customers</div> </div>
                    </div>
                    
                    <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-brand-600 bg-white rounded-xl border-style text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send h-10 w-10 stroke-1 text-brand-600 mx-auto">
                        <line x1="22" y1="2" x2="11" y2="13"></line>
                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                        </svg>
                        <div className="content mt-6"> <div className="title h5 text-xl font-semibold hover:text-brand-600">Sell pitches to vendors</div> </div>
                    </div>
                    
                    <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-brand-600 bg-white rounded-xl border-style text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star h-10 w-10 stroke-1 text-brand-600 mx-auto">
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                        </svg>
                        <div className="content mt-6"> <div className="title h5 text-xl font-semibold hover:text-brand-600">Collect the ticket fee in your own PayPal or Stripe account</div> </div>
                    </div>
                    
                    <div className="group px-6 py-8 hover:bg-white dark:hover:bg-slate-900 hover:shadow dark:hover:shadow-gray-800 duration-500 ease-in-out border-b-[3px] border-transparent hover:border-brand-600 bg-white rounded-xl border-style text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark h-10 w-10 stroke-1 text-brand-600 mx-auto">
                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        <div className="content mt-6"> <div className="title h5 text-xl font-semibold hover:text-brand-600">Only pay for the tickets you sell </div> </div>
                    </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 ltr:md:text-left rtl:md:text-right text-center justify-center">
                            <div className="lg:col-start-2 lg:col-span-10">
                                <div className="relative z-50"> <img src="/images/cta-bg.webp" className="rounded-md shadow-lg" alt="cta bg"/>
                                
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 sm:h-2/3 h-4/5  bg-brand-600 z-0"></div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px] text-center">
                    <h2 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50 text-brand-600">Sell Tickets To Your Event Online </h2>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center"><div className="relative overflow-hidden text-transparent -m-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg><div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"> <ComputerDesktopIcon className="h-6 w-6 text-gray-500" /> </div></div><div className="mt-6"> 
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'Box_section1'){
                            return  <><div className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out"><h4>{fetchContents.title}</h4></div><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                        }})}
                    </div>
                    </div>
                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto">
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        </svg>
                        <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"><PresentationChartLineIcon className="h-6 w-6 text-gray-500" /></div>
                        </div>
                        <div className="mt-6"> 
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'Box_section2'){
                            return  <><div className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out"><h4>{fetchContents.title}</h4></div><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                        }})}
                        </div>
                    </div>
                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto">
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                        </svg>
                        <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"> <ChartBarIcon className="h-6 w-6 text-gray-500" /> </div>
                        </div>
                        <div className="mt-6">
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'Box_section3'){
                            return  <><div className="text-lg font-medium group-hover:text-white transition-all duration-500 ease-in-out"><h4>{fetchContents.title}</h4></div><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                        }})}
                        </div>
                    </div>
                    
                    </div>
                    
                    <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px] text-center">
                    <div className="mt-6"> <a href="http://app.coconuttickets.com/sign-up" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md mt-2"><i className="uil uil-airplay"></i> Get Started</a> </div>
                    </div>
                </div>
                
                
                <div className="md:container md:mx-auto md:mt-24 mt-16 relative"> <span className="absolute blur-[200px] md:w-[600px] md:h-[600px] rounded-full top-1/2 -translate-y-1/2 ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2 bg-brand-600/20"></span>
                    <div className="md:grid grid-flow-row-dense md:grid-cols-2 gap-4 max-sm:px-6">
                        <div className="md:col-span-1 bg-nr width100" style={{backgroundImage: `url(${bghomebannethreeimg})` }}>
                            <div className=""> <img src="/images/bg2.webp" alt="bg2" className="width100"/> </div>
                        </div>
                        <div className="md:col-span-1 mt-8 grid content-center">
                            <div className="ltr:lg:ml-5 rtl:lg:mr-5 zindexup">
                            {posts?.map(fetchContents => {
                            if(fetchContents.section === 'powerful_digitalization'){
                            return  <><h5 className="text-brand-600 text-sm font-bold uppercase mb-2">{fetchContents.sub_title}</h5>
                            <h3 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-4xl mb-5 z-50">{fetchContents.title}</h3>
                            <p className="text-slate-400 max-w-xl mb-6 text-xl">{fetchContents.content}</p>
                            <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600/5 hover:bg-brand-600 border-brand-600/10 hover:border-brand-600 text-brand-600 hover:text-white rounded-full">{fetchContents.button_text}</a> 
                            </>
                        }})}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden md:pb-24 pb-16">
                <div className="md:container md:mx-auto md:mt-24 mt-16 max-sm:px-6">
                    <div className="md:grid md:grid-cols-3 gap-4">
                        <div className="md:col-span-2">
                            <h3 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50">Events you can sell <br/>
                                online with Coconut Tickets
                            </h3>
                            <Accordion alwaysOpen={true}>
                            {faqs?.map((fetchfaq) => { 
                                return   <Accordion.Panel>
                                        <Accordion.Title>
                                        <span className='text-brand-600 font-semibold'>{fetchfaq.title}</span>
                                        </Accordion.Title>
                                        <Accordion.Content>
                                        <p className="text-slate-600 text-lg">{fetchfaq.content}</p>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                            })}
                            </Accordion>
                        </div>
                        <div className="md:col-span-1">
                            <div className="relative order-1 md:order-2">
                                <div className="items-center gap-4 lg:gap-0 bg-ng bg-center" style={{backgroundImage: `url(${bghomebannefourimg})`}}>
                                    <img src="/images/bg3.webp" alt="faq banner" className="width100"/> 
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3 mx-auto text-center">
                            <div className="mb-6"> 
                                <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md mt-2">
                                    <i className="uil uil-airplay"></i> Get Started
                                </a> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-4xl leading-normal font-semibold">What Our Users Say</h3>
                    <p className="text-slate-400 max-w-xl mx-auto text-xl">Discovering Excellence Together - A Tapestry of User Experiences</p>
                    </div>
                    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
                        <Carousel>
                            {reviews?.map((items) => {
                                return <div className="grid sm:grid-cols-3 max-sm:grid-cols-1 gap-4">
                                            {items?.map((item) => {
                                                return  <div className="coco-carosel-height">
                                                    <Card className="caro-card">
                                                        <p className="font-normal text-gray-700 dark:text-gray-400">
                                                            {item.content}
                                                        </p>
                                                        <div className="text-center mt-5">
                                                            <h6 className="mt-2 font-semibold"> {item.title}</h6>
                                                            <span className="text-slate-400 text-sm">{item.sub_title}</span>
                                                        </div>
                                                    </Card>
                                                
                                                </div> 
                                            })}
                                        </div>
                            })}
                        </Carousel>
                    </div>
                </div>
            </section>
            </>
        )
    }
    return (
        <>
            {ShowhomeContents()}
            {ShowhomeCarousel()}
            {ShowhomeFaq()}
            {MobileDeviceDetector()}
           
           
        </>
    )
}
export default Home;