
import React from 'react';
// import { LockClosedIcon } from '@heroicons/react/20/solid'
import { NavLink } from "react-router-dom";


function Footer(){
return(
    <>
        <div className="footer bg-slate-900 relative text-gray-200 dark:text-gray-200">
            <div className="md:container md:mx-auto">
                <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className="py-[60px] px-0">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-12 max-sm:px-4"> <NavLink href="/" className="text-[22px] focus:outline-none"> <img src="/images/logo-light.webp" alt=""/> </NavLink>
                        <p className="mt-6 text-gray-300 font-light">For event organizers of small to medium size events who need to sell tickets and vendor pitches (vendor slots) to their events, Coconut Tickets is a quality online ticketing platform that is a complete and easy to use solution for ticket buying customers and event organizers alike, supporting a wide range of event ticket and pitch business models.</p>
                        </div>
                        
                        <div className="lg:col-span-2 md:col-span-4 max-sm:px-4">
                        <h5 className="tracking-[1px] text-gray-100 font-normal">Company</h5>
                        <ul className="list-inside footer-list mt-10">
                            <li><NavLink to={'sell-tickets'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                                How to sell tickets</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'/features'}  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg>
                                Features</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'/how-to-get-vendors'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg>
                                Get vendors</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'/gdpr'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> 
                                GDPR</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'/privacy-policy'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> 
                                Privacy policy</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'/coconut-tickets-community-help-centre'}  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> 
                                Help centre</NavLink></li>
                          
                        </ul>
                        </div>
                        
                        <div className="lg:col-span-3 md:col-span-4 max-sm:px-4">
                        <h5 className="tracking-[1px] text-gray-100 font-normal">Usefull Links</h5>
                        <ul className="list-inside footer-list mt-10">
                            <li><NavLink to={'/eventbrite-alternative'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Eventbrite alternative</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'sell-time-slot-tickets'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Sell time slot tickets</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'sell-tickets-on-wordpress'}  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Sell tickets on WordPress</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'school-events'} className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Schools and Colleges</NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'ticketing-software-for-fundraisers'}  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Fundraisers </NavLink></li>
                            <li className="mt-[10px]"><NavLink to={'terms-of-use'}  className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
</svg> Terms of use</NavLink></li>
                        </ul>
                        </div>
                        
                        <div className="lg:col-span-3 md:col-span-4 max-sm:px-4">
                        <h5 className="tracking-[1px] text-gray-100 font-normal">Newsletter</h5>
                        <p className="mt-10">Sign up and receive the latest tips via email.</p>
                        <form>
                            <div className="grid grid-cols-1">
                            <div className="foot-subscribe my-3">
                                <label className="form-label">Write your email <span className="text-red-600">*</span></label>
                                <div className="form-icon relative mt-2  bg-gray-800 rounded-lg"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail w-4 h-4 absolute top-3 ltr:left-4 rtl:right-4 ml-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                <input type="email" className="form-input bg-gray-800 border border-gray-800 text-gray-100 px-4 py-2 pl-8 focus:shadow-none w-full rounded-md" placeholder="Email" name="email" required="" />
                                </div>
                            </div>
                            <button type="submit" id="submitsubscribe" name="send" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-4 py-2">Subscribe</button>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container text-center">
                <div className="grid md:grid-cols-2 items-center">
                    <div className="ltr:md:text-left rtl:md:text-right text-center">
                        <p className="mb-0">@ 2020 Coconut Tickets. All rights reserved.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
)
}
export default Footer;