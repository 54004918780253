//import {ChartBarIcon} from '@heroicons/react/24/outline'
import {ChartBarIcon,ComputerDesktopIcon,PresentationChartLineIcon,CurrencyDollarIcon,TicketIcon,ClockIcon} from '@heroicons/react/24/solid'
import React,{useState, useEffect} from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import { useQuery } from "react-query";
import bghomebannerimg from '../images/slider_fund.webp';
import bghomebannetwoimg from '../images/bg-back1.webp';
import bghomebannethreeimg from '../images/bg-back2.webp';
import bghomebannefourimg from '../images/bg-back3.webp';
import sanitizeHtml from 'sanitize-html-react';
import {Carousel,Rating,Card,Accordion} from 'flowbite-react';
import { NavLink } from "react-router-dom";
function Fundraiser() {
    const user_token = localStorage.getItem("coconuttoken");
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_token}`
    };
    const BASE_URL = "https://application.coconuttickets.com";
    const [isMobile, setIsMobile] = useState(false);
    const [faqs, setFaq] = useState([]);
    const [posts, setPosts] = useState([]);
    const [carousels, setCarousel] = useState([]);
    const fetchContents = async () => {
        const response = await axios.get( `${BASE_URL}/api/public_contents?page=fundraiser`, { headers });
        return response.data;
    };
    const fetchfaq= async () => {
        const response = await axios.get( `${BASE_URL}/api/public_faqs?page=fundraiser`, { headers });
        return response.data;
    };
    const ShowhomeContents = () => {
        const { isLoading, isSuccess, data, error }= useQuery('fetchContents',fetchContents)
        useEffect(() => {
            if(isLoading === false && data){
                setPosts(data);
            }
        }, [isLoading, data])
        if (isLoading) return <div className="blur-2xlv h-screen w-screen">
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="container mx-auto mt-80">
                                            <div className="grid">
                                                <div className="space-y-3">
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             </div>
        if (isSuccess) return  <Homefullpage />
    }
    const ShowhomeCarousel = () => {
        useEffect( () => { 
            async function fetchData() {
                axios.get(`${BASE_URL}/api/public_carousels?page=home`, { headers })
                .then(response => {
                    var rows = response.data;
                    setCarousel(rows);
                })
                .catch(error => {
                    console.error(error);
                })
    
            }
            fetchData();
        }, []);
    }
    const ShowhomeFaq = () => {
        const { isLoading, isError, data, error }= useQuery('fetchfaq',fetchfaq,{
            onSuccess: (data) =>  {
            // Update local state with the data from the query
            setFaq(data);
        }})
    
    }
    const MobileDeviceDetector = () => {
      //  const [isMobile, setIsMobile] = useState(false);
      
        useEffect(() => {
          const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed
       
          setIsMobile(mobileMediaQuery.matches);
      
        }, []);
      
        
      }
    const reviews  =  carousels.reduce((resultArray, item, index) => { 
        var chunkIndex = Math.floor(index/3) 
        if(isMobile){
            chunkIndex = Math.floor(index) 
        }  
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)
      
        return resultArray
    }, [])
    const Bannerhome = () =>{
       return (posts.length != 0 ) ? (<section className="relative table w-full md:py-36 lg:py-44 studio-wrapper overflow-hidden">
                <div className="absolute inset-0 home-banner-bg" id="overlay" style={{backgroundImage: `url(${bghomebannerimg})`}}></div>
                    {posts?.map(fetchContents => {
                        if(fetchContents.section === 'banner'){
                            return  <div className="md:container md:mx-auto">
                                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                                            <div className="md:col-span-6 z-0 sm:p-6 bg-gray-700/80 rounded-md">
                                                <div className="ltr:md:mr-6 rtl:md:ml-6">
                                                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl  z-50 text-gray-100">{fetchContents.title}
                                                </h1>
                                                <h4 className="text-brand-600 text-xl block font-bold mb-5">{fetchContents.sub_title} </h4>
                                                <div className="mt-6"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">{fetchContents.button_text} </a> </div>
                                                <br/>
                                                </div>
                                            </div>
                                            <div className="md:col-span-6 max-sm:px-6 max-sm:hidden">
                                                <div className="relative"> <img className="float-right" src="/images/marketing.webp" alt="banner1" /> </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                        }
                    })}   
            </section>)  : (<section className="relative table w-full md:py-36 lg:py-44 studio-wrapper overflow-hidden">
                <div className="absolute inset-0 home-banner-bg" id="overlay" style={{backgroundImage: `url(${bghomebannerimg})`}}></div>
                    {posts?.map(fetchContents => {
                        if(fetchContents.section === 'banner'){
                            return  <div className="md:container md:mx-auto">
                                        <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
                                            <div className="md:col-span-6 z-0 sm:p-6 bg-gray-700/80 rounded-md">
                                                <div className="ltr:md:mr-6 rtl:md:ml-6">
                                                <h1 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl  z-50 text-gray-100">{fetchContents.title}
                                                </h1>
                                                <h4 className="text-brand-600 text-xl block font-bold mb-5">{fetchContents.sub_title} </h4>
                                                <div className="mt-6"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">{fetchContents.button_text} </a> </div>
                                                <br/>
                                                </div>
                                            </div>
                                            <div className="md:col-span-6 max-sm:px-6 max-sm:hidden">
                                                <div className="relative"> <img className="float-right" src="/images/marketing.webp" alt="banner1" /> </div>
                                            </div>
                                    
                                        </div>
                                    </div>
                        }
                    })}   
            </section>)
    }
    const Homefullpage = () =>{
        return(
            <>
             {posts?.map(fetchContents => {
                if(fetchContents.section === 'meta_data'){
                    return <Helmet><title>{fetchContents.title}</title><meta name="description" content={fetchContents.content} /> </Helmet>
                }
            })}
            <Bannerhome />
            <section className="relative bg-gray-50 dark:bg-slate-800 py-16 md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid md:grid-cols-1 grid-cols-1 items-center gap-[30px]">
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'features'){
                                return <div className="ltr:lg:ml-5 rtl:lg:mr-5 max-sm:px-4">
                                            <h2 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50 text-brand-600 text-center">{fetchContents.title}</h2>
                                            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                                                <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center"><div className="relative overflow-hidden text-transparent -m-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg><div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"> <ComputerDesktopIcon className="h-6 w-6 text-gray-500" /> </div></div><div className="mt-6"> 
                                                    {posts?.map(fetchContents => {
                                                        if(fetchContents.section === 'Box_section1'){
                                                        return  <><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.title}</p></>
                                                    }})}
                                                </div>
                                            </div>
                                        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                            <div className="relative overflow-hidden text-transparent -m-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto">
                                                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                                </svg>
                                                <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"><ClockIcon className="h-6 w-6 text-gray-500" /></div>
                                            </div>
                                            <div className="mt-6"> 
                                            {posts?.map(fetchContents => {
                                                if(fetchContents.section === 'Box_section2'){
                                                return  <><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.title}</p></>
                                            }})}
                                            </div>
                                        </div>
                                        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                            <div className="relative overflow-hidden text-transparent -m-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto">
                                                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                                </svg>
                                                <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"> <CurrencyDollarIcon className="h-6 w-6 text-gray-500" /> </div>
                                            </div>
                                            <div className="mt-6">
                                            {posts?.map(fetchContents => {
                                                if(fetchContents.section === 'Box_section3'){
                                                return  <><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.title}</p></>
                                            }})}
                                            </div>
                                        </div>
                                        <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                                <div className="relative overflow-hidden text-transparent -m-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#eef" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon h-24 w-24 fill-brand-600/5 group-hover:fill-white/10 mx-auto">
                                                    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                                    </svg>
                                                    <div className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-brand-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center"> <TicketIcon className="h-6 w-6 text-gray-500" /> </div>
                                                </div>
                                                <div className="mt-6">
                                                {posts?.map(fetchContents => {
                                                    if(fetchContents.section === 'Box_section4'){
                                                    return  <><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.title}</p></>
                                                }})}
                                                </div>
                                        </div>
                                        
                                        </div>
                                        </div>
                            }
                        })}    
                    </div>
                </div>
            </section>
            <section className="relative overflow-hidden md:pb-24 pb-16">
                <div className="md:container md:mx-auto md:mt-24 mt-16 max-sm:px-6">
                    <div className="md:grid md:grid-cols-3 gap-4">
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'events_in_minutes'){
                                const clean_content = sanitizeHtml(fetchContents.content, {
                                    allowedTags: ['b', 'i', 'em', 'strong', 'a','ul','li','svg','path','div'],
                                    allowedAttributes: {
                                    a:  ['href', 'target'],
                                    svg: ['class','viewBox','fill','xmlns'],
                                    path:['fill-rule','clip-rule','d'],
                                    div: ['class','id'],
                                    ul: ['class'],
                                    li: ['class']
                                    }
                                });
                                const theObj = {__html:clean_content};
                            return  <><div className="md:col-span-2 content-center">
                                        <h3 class="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50">{fetchContents.title}
                                        </h3>
                                        <div className="" dangerouslySetInnerHTML={theObj}></div>
                                    </div>
                                    <div className="md:col-span-1">
                                        <div className="relative order-1 md:order-2">
                                            <div className="items-center gap-4 lg:gap-0 bg-ng bg-center" style={{backgroundImage: `url(${bghomebannefourimg})`}}>
                                                <img src="/images/bg3.webp" alt="faq banner" className="width100"/> 
                                            </div>
                                        </div>
                                    </div>
                                <div className="col-span-3 mx-auto text-center">
                                    <div className="mb-6"> 
                                  
                                        <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md mt-2">
                                            <i className="uil uil-airplay"></i> {fetchContents.button_text}
                                        </a> 
                                    </div>
                                </div></>
                                
                            }
                        })}
                    </div>
                </div>
            </section>
            <section className="relative bg-gray-50 dark:bg-slate-800 py-16 md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    
                    <div className="grid grid-cols-3 items-center gap-[30px]">
                        <div class="flex flex-col"></div>
                        <div class="flex flex-col justify-between items-center lg:flex-row lg:items-start">
                            <div class="w-full flex-1 p-8 order-1 shadow-xl rounded-3xl bg-gray-900 text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
                            <div class="mb-8 pb-8 flex justify-center items-center border-b border-gray-600">
                                <div class="ml-5">
                                <span class="block text-3xl font-semibold text-white">Hassle Free </span>
                                <span class="text-3xl font-bold text-white">Pricing Plans</span>
                                </div>
                            </div>
                            <ul class="mb-10 font-medium text-xl">
                                <li class="flex mb-6">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                                <span class="ml-3">Free Trial</span>
                                </li>
                                <li class="flex mb-6">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                                <span class="ml-3">Free Tickets for <span class="text-white">Free Event</span></span>
                                </li>
                                <li class="flex">
                                <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                                <span class="ml-3"><span class="text-white">1.50 Cent/Ticket </span> + Payment Processing Fee
                        1%/Ticket + Payment Processing Fee</span>
                                </li>
                            </ul>
                            <a href="#/" class="flex justify-center items-center bg-brand-600 rounded-xl py-6 px-4 text-center text-white text-2xl">
                                Start Selling or Contact Us for Custom Solution
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative bg-gray-50 dark:bg-slate-800 py-16 md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid md:grid-cols-1 grid-cols-1 items-center gap-[30px]">
                        {posts?.map(fetchContents => {
                            if(fetchContents.section === 'sell_more'){
                                return <div className="ltr:lg:ml-5 rtl:lg:mr-5 max-sm:px-4">
                                            <h2 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 z-50 text-brand-600 text-center">Sell More Tickets</h2>
                                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                                                <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                                    <div className="mt-6"> 
                                                    {posts?.map(fetchContents => {
                                                        if(fetchContents.section === 'sell_more'){
                                                        return  <><h3 className="text-slate-600 text-2xl">{fetchContents.title}</h3><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                                                    }})}
                                                    </div>
                                                </div>
                                            <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                                
                                                <div className="mt-6"> 
                                                {posts?.map(fetchContents => {
                                                    if(fetchContents.section === 'sell_more1'){
                                                    return  <><h3 className="text-slate-600 text-2xl">{fetchContents.title}</h3><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                                                }})}
                                                </div>
                                            </div>
                                            <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-brand-600 dark:hover:bg-brand-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                                            
                                                <div className="mt-6">
                                                {posts?.map(fetchContents => {
                                                    if(fetchContents.section === 'sell_more2'){
                                                    return  <><h3 className="text-slate-600 text-2xl">{fetchContents.title}</h3><p className="text-slate-600 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3 text-lg">{fetchContents.content}</p></>
                                                }})}
                                                </div>
                                            </div>
                                        </div></div>
                                        
                            }
                        })}   
                         <div className="mt-6 mx-auto text-center"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">Start a Free Trial</a> </div> 
                    </div>
                </div>
            </section>
            <section className="relative bg-gray-50 dark:bg-slate-800 py-16 md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-6">
                    <div className="grid md:grid-cols-1 grid-cols-1 items-center gap-[30px]">
                        <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 mt-8 gap-[30px]"> 
                            <h2 className="text-slate-600 text-2xl mx-auto text-center">Get Started with the Best Ticketing Software for Fundraisers</h2>
                            <p className="text-slate-600 text mx-auto text-center">Maximize your fundraising/non profit event's success using our powerful ticketing software designed just for you!</p>
                        </div>
                        <div className="mt-6 mx-auto text-center"> <a href="http://app.coconuttickets.com/pricing" className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md px-6 py-4">Start a Free Trial</a> </div> 
                    </div>
                </div>
            </section>
            </>
        )
    }
    return (
        <>
            {ShowhomeContents()}
            {ShowhomeCarousel()}
            {ShowhomeFaq()}
            {MobileDeviceDetector()}
           
           
        </>
    )
}
export default Fundraiser;